import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import blue from '@material-ui/core/colors/blue';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  icon: {
    margin: theme.spacing.unit * 2,
  },
  iconHover: {
    margin: theme.spacing.unit * 2,
    '&:hover': {
      color: blue[800],
    },
  },
});

function HomeIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
  );
}

function HomeIcons(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <HomeIcon className={classes.iconHover} color="error" style={{ fontSize: 30 }} />
    </div>
  );
}

HomeIcons.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomeIcons);